import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia } from '@material-ui/core';
import logoCalendar from '../assets/add-to-calendar.png';

export default function Button({ children, onClick }) {
  return (
    <CardMedia
      component="img"
      alt={children}
      image={logoCalendar}
      title={children}
      onClick={onClick}
      style={{
        maxWidth: 35 * (window.zoom ? window.zoom : 1),
        cursor: 'pointer',
      }}
    />
  );
}

Button.propTypes = {
  children: PropTypes.objectOf(PropTypes.object()).isRequired,
  onClick: PropTypes.objectOf(PropTypes.object()).isRequired,
};
