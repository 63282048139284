import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toNumber } from 'lodash';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
}));

export default function SelectorCategories({ options, selectOption }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    cat: '0',
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    selectOption(toNumber(event.target.value));
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        ref={inputLabel}
        key={inputLabel}
        htmlFor="outlined-cat-native-simple"
        style={{
          fontSize: 16,
        }}
      >
        Catégorie
      </InputLabel>
      <Select
        native
        value={state.cat}
        onChange={handleChange('cat')}
        labelWidth={labelWidth}
        inputProps={{
          name: 'cat',
          id: 'outlined-cat-native-simple',
        }}
        style={{
          fontSize: 16,
        }}
      >
        <option value="0">Toutes</option>
        {options.map((option) => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

SelectorCategories.propTypes = {
  options: PropTypes.objectOf(PropTypes.object()).isRequired,
  selectOption: PropTypes.objectOf(PropTypes.object()).isRequired,
};
