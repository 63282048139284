import React from 'react';
import PropTypes from 'prop-types';
import DialogBox from '../components/DialogBox';

export default function CalendarModal({ children, isOpen, onRequestClose }) {
  console.log(children);
  return (
    <DialogBox
      open={isOpen}
      content={children}
      title="Ajouter au calendrier"
      handleClose={onRequestClose}
    />
  );
}

CalendarModal.propTypes = {
  children: PropTypes.objectOf(PropTypes.object()).isRequired,
  onRequestClose: PropTypes.objectOf(PropTypes.object()).isRequired,
  isOpen: PropTypes.bool,
};
