import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Grid, Typography } from '@material-ui/core';
import { size, slice } from 'lodash';
import request from 'superagent';
import ReactPaginate from 'react-paginate';

import { muiTheme, defaultThemeOption } from './utils/tools';
import EventsCard from './components/EventsCard';
import Loader from './utils/Loader';
import config from './config';
import Selector from './utils/Selector';
import SelectorCategories from './utils/SelectorCategories';
import {
  filterEventList,
  GenerateDateFilterListDataSource,
  GenerateCategoryFilterListDataSource,
  GenerateCategoryIdListDataSource,
} from './utils/dates';

const NUMBER_OF_ITEMS_PER_PAGE =
  parseInt(window.itemPerPageEvent, 10) > 0
    ? parseInt(window.itemPerPageEvent, 10)
    : 10;

class Agenda extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN } = this.props;
    if (cityId || aggloId) {
      this.fetchEvents(cityId, aggloId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchEventsByCode(cityINSEE, aggloSIREN);
    }
    this.fetchCategories();
  }

  fetchEvents(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .query({ source: 'widget' })
      .then((res) => res.body)
      .then((events) => {
        this.setState({
          events: filterEventList(events),
          fetched: true,
          dateOptions: GenerateDateFilterListDataSource(events),
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchCategories = () => {
    request
      .get(config.apiRoot + config.apiCategories)
      .then((res) => res.body)
      .then((categories) => {
        this.setState({ categoriesList: categories });
      })
      .catch((err) => {
        console.log('error during call : ' + err);
      });
  };

  fetchEventsByCode(cityINSEE, aggloSIREN) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .then((res) => res.body)
      .then((events) => {
        this.setState({
          events: filterEventList(events),
          fetched: true,
          dateOptions: GenerateDateFilterListDataSource(events),
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  selectFilter(option) {
    console.group('Select filter option');
    console.log(option);
    console.groupEnd();
    this.setState({ filterSelected: option, currentPage: 0 });
  }

  selectCategoryFilter(option) {
    console.group('Select category filter option');
    console.log(option);
    console.groupEnd();
    this.setState({ catSelected: option, currentPage: 0 });
  }

  handlePageClick = (data) => {
    console.log(data);
    this.setState({ currentPage: data.selected });
    document.activeElement.blur();

    const elmnt = document.getElementById('intramuros_events');
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  };

  render() {
    const {
      events,
      error,
      fetched,
      filterSelected,
      catSelected,
      dateOptions,
      currentPage,
      categoriesList,
    } = this.state;
    const filter = window.dateFilter;
    if (!events) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    const customTypography = window.forceFont
      ? { fontFamily: ['Lato', 'sans-serif'].join(',') }
      : { fontFamily: 'inherit' };

    const themeOptions = {
      ...defaultThemeOption,
      typography: customTypography,
    };

    const theme = muiTheme(themeOptions);

    let divStyle = { overflowY: 'auto', overflowX: 'hidden' };
    if (window.maxHeight > 0) {
      divStyle = { ...divStyle, maxHeight: parseInt(window.maxHeight, 10) };
    }
    if (window.backgroundColor) {
      divStyle = { ...divStyle, backgroundColor: window.backgroundColor };
    }

    const categoryFilterList = GenerateCategoryFilterListDataSource(
      GenerateCategoryIdListDataSource(events),
      categoriesList,
    );

    let myEvents = events;
    if ((filter && filterSelected) || catSelected) {
      myEvents = filterEventList(
        myEvents,
        filterSelected?.month,
        filterSelected?.year,
        catSelected,
      );
    }

    const numberOfEvents = size(myEvents);
    const numberOfPages = Math.ceil(numberOfEvents / NUMBER_OF_ITEMS_PER_PAGE);
    console.log('number of pages = ' + numberOfPages);

    //  Calculate list of current page
    if (numberOfPages > 1) {
      myEvents = slice(
        myEvents,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE + NUMBER_OF_ITEMS_PER_PAGE,
      );
    }

    return (
      <div style={divStyle}>
        <MuiThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginBottom: 25,
            }}
          >
            {filter && size(events) > 0 ? (
              <Selector
                options={dateOptions}
                selectOption={(option) => this.selectFilter(option)}
              />
            ) : null}
            {size(categoryFilterList) > 1 ? (
              <SelectorCategories
                options={categoryFilterList}
                selectOption={(option) => this.selectCategoryFilter(option)}
              />
            ) : null}
          </Grid>

          {myEvents.map((theEvent) => (
            <EventsCard event={theEvent} key={theEvent.id} />
          ))}
          {size(myEvents) > 0 ? null : (
            <div style={divStyle}>
              <MuiThemeProvider theme={theme}>
                <Typography
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    marginTop: 20,
                    marginBottom: 20,
                    color: '#333',
                  }}
                >
                  {filterSelected || catSelected
                    ? 'Aucun événement pour ces critères'
                    : 'Aucun événement programmé'}
                </Typography>
              </MuiThemeProvider>
            </div>
          )}
          {numberOfPages > 1 ? (
            <Grid container justify="center" style={{ marginTop: 30 }}>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={numberOfPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1} // saut de page: quand on clique sur les ...
                onPageChange={this.handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={currentPage}
              />
            </Grid>
          ) : null}
        </MuiThemeProvider>
      </div>
    );
  }
}

Agenda.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default Agenda;
